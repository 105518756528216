import { default as _91_46_46_46slug_93GKPCvgwpzJMeta } from "/codebuild/output/src882816134/src/seb-brand-platform/apps/schaerer/pages/[...slug].vue?macro=true";
import { default as component_45stubqVtH2dqkFtMeta } from "/codebuild/output/src882816134/src/seb-brand-platform/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubqVtH2dqkFt } from "/codebuild/output/src882816134/src/seb-brand-platform/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en_com",
    path: "/en_com/:slug(.*)*",
    component: () => import("/codebuild/output/src882816134/src/seb-brand-platform/apps/schaerer/pages/[...slug].vue")
  },
  {
    name: "slug___en_us",
    path: "/en_us/:slug(.*)*",
    component: () => import("/codebuild/output/src882816134/src/seb-brand-platform/apps/schaerer/pages/[...slug].vue")
  },
  {
    name: "slug___de_ch",
    path: "/de_ch/:slug(.*)*",
    component: () => import("/codebuild/output/src882816134/src/seb-brand-platform/apps/schaerer/pages/[...slug].vue")
  },
  {
    name: component_45stubqVtH2dqkFtMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubqVtH2dqkFt
  },
  {
    name: component_45stubqVtH2dqkFtMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubqVtH2dqkFt
  },
  {
    name: component_45stubqVtH2dqkFtMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubqVtH2dqkFt
  },
  {
    name: component_45stubqVtH2dqkFtMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stubqVtH2dqkFt
  },
  {
    name: component_45stubqVtH2dqkFtMeta?.name,
    path: "/de-CH-sitemap.xml",
    component: component_45stubqVtH2dqkFt
  }
]